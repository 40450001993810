import React from 'react';
import PropTypes from 'prop-types';
import { DialogContent, Drawer as MuiDrawer } from '@mui/material';
import cx from 'classnames';
import { Clickable } from 'phoenix-components';
import {
  filterIcon
} from 'components/shared/Icons/Filters';
import {
  sortIcon
} from 'components/shared/Icons/Filters';
import {
  crossIcon
} from 'components/shared/Icons/Filters';
import { useLocalCart } from 'contexts/cart';
import {sumBy} from 'ui/lodash'
import styles from './BottomDrawer.module.css';

const backIcon = '/images/store/next.svg';

export function BottomDrawer({
  title,
  children,
  onClose,
  classes,
  closeButton,
  ...other
}) {

  const isFilters = title === 'Filters';
  const isSort = title === 'Sort';

  const cartData = useLocalCart();
  const productsCount = sumBy(cartData?.products || [], 'quantity');

  return (
    <MuiDrawer
      anchor="bottom"
      open={true}
      PaperProps={{
        classes: {
          root: styles.paper,
        }
      }}
      onClose={onClose}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      <DialogContent
        className={cx(
          styles.overflow,
          classes.container, {
            [styles.bottom]: productsCount > 0
          }
        )}>
        {(title || closeButton) && !isFilters && !isSort &&(
          <div className={styles.row}>
            {closeButton && (
              <Clickable onClick={onClose}>
                <img className={styles.closeIcon} src={backIcon} alt="" />
              </Clickable>
            )}
            {title ? <div className={cx(styles.heading, classes.heading)}>{title}</div> : (
              <div className="spacer">&nbsp;</div>
            )}
            {closeButton && <div>&nbsp;</div>}
          </div>
        )}
        {isFilters && (
          <div className={styles.row}>
            <div className={cx(styles.heading, classes.heading)}>{title}</div>
            <div className={cx(styles.fIcon)}>{filterIcon()}</div>
          </div>
        )}
        {isSort && (
          <div className={styles.row}>
            <div className={cx(styles.heading, classes.heading)}>{title}</div>
            <div className={cx(styles.sIcon)}>{sortIcon()}</div>            
            <div className={cx(styles.cIcon)}>           
              <Clickable onClick={onClose}>
                {crossIcon()}
              </Clickable>
            </div>            
          </div>
        )}
        <div className={cx(styles.container, classes.container)}>
          {children}
        </div>
      </DialogContent>
    </MuiDrawer>
  );

}

BottomDrawer.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node.isRequired,
    PropTypes.arrayOf(PropTypes.node).isRequired
  ]).isRequired,
  onClose: PropTypes.func,
  classes: PropTypes.object,
  closeButton: PropTypes.bool,
};

BottomDrawer.defaultProps = {
  title: null,
  onClose: null,
  classes: {},
  closeButton: false,
};
